import './src/styles/global.css';
import './static/leaflet/leaflet.css';

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Include leaflet
    require('./static/leaflet/leaflet.js');

    // Initially implement cookie bot
    const script = document.createElement("script");
    script.src = `https://consent.cookiebot.com/uc.js`;
    script.setAttribute("type", "text/javascript");
    script.setAttribute("data-cbid", "06049450-8306-4c30-95b9-f9b77839bba2");
    script.setAttribute("id", "Cookiebot");
    script.async = true;
    document.head.appendChild(script);
  }
};